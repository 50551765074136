<template>
  <v-navigation-drawer
    app
    width="258"
    color="primary"
    permanent
    :mini-variant.sync="$store.state.isActiveSidebar"
    mini-variant-width="100"
  >
    <router-link :to="{ name: 'home' }">
      <img
        src="@/assets/images/logo-white.svg"
        class="pa-3 pb-0"
        :style="$store.state.isActiveSidebar ? 'width : 80px; padding-left: 25px !important' : ''"
      />
    </router-link>
    <v-list>
      <div v-for="(item, index) in listRole" :key="item.title">
        <div v-if="item.title === 'Schedule' && item.accountType === 'Student'">
          <v-list-item
            v-for="itemChild in item.items"
            :key="itemChild.title"
            :class="
              $route.path.includes(itemChild.path)
                ? 'other-menu-title-active-border menu-title dashboard pb-2 pt-2'
                : 'menu-title dashboard pb-2 pt-2'
            "
            :to="itemChild.path"
            @click="removeClass"
          >
            <img :src="itemChild.iconImg" alt="" class="mr-4" />
            <v-list-item-title v-text="itemChild.title"> </v-list-item-title>
          </v-list-item>
        </div>
        <div v-else-if="item.accountType === 'Teacher'">
          <v-list-item
            v-for="itemChild in item.items"
            :key="itemChild.title"
            :class="
              $route.path.includes(itemChild.path)
                ? 'other-menu-title-active-border menu-title dashboard pb-2 pt-2'
                : 'menu-title dashboard pb-2 pt-2'
            "
            :to="itemChild.path"
          >
            <img :src="itemChild.iconImg" alt="" class="mr-4" />
            <v-list-item-title v-text="itemChild.title"> </v-list-item-title>
          </v-list-item>
        </div>
        <v-list-group
          v-else
          v-model="item.active"
          no-action
          :append-icon="item.items.length > 0 ? 'arrow_drop_down' : ''"
        >
          <template v-slot:activator>
            <v-list-item :to="item.path">
              <v-list-item-icon
                class="my-2"
                style="margin-right: 14px !important; margin-left: -19px"
              >
                <img v-if="item.icon.indexOf('isax')" :src="item.icon" alt="" />
                <i v-else :class="'isax fs-24 ' + item.icon"></i>
              </v-list-item-icon>
              <v-list-item-content
                :class="
                  isRootItem(index) || isGroupItem(index - 4)
                    ? 'dashboard menu-title-active-border'
                    : 'dashboard menu-title'
                "
              >
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-for="child in item.items" :key="child.title" :to="child.path">
            <v-list-item-content :class="isActiveItemChild(child.path, child.title)">
              <v-list-item-title @click="filterPopUp(child.title)">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">{{ child.title }}</div>
                  </template>
                  <div v-html="formatTitle(child.title)"></div>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
    <popup-filter
      @confirm="confirmFilter"
      @cancel="cancelFilter"
      :maxWidth="maxWidth"
      :width="width"
      ref="confirmFilter"
    >
      <v-card-title slot="title" class="pt-0 mb-5 pa-0">
        <h4 class="mb-5">Filter the feedback</h4>
        <div class="mb-3">
          <!-- <date-picker
            :model="fromDate"
            @update:model="updateFromDate"
            title="From date"
            style="width: 300px"
          ></date-picker> -->
          <v-text-field
            type="date"
            label="From date"
            style="width: 330px"
            v-model="fromDate"
            outlined
            dense
            hide-details
          >
          </v-text-field>
        </div>
        <div>
          <!-- <date-picker
            :model="toDate"
            @update:model="updateToDate"
            title="To date"
            style="width: 300px"
          ></date-picker> -->
          <v-text-field
            type="date"
            label="To date"
            style="width: 330px"
            v-model="toDate"
            outlined
            dense
            hide-details
          >
          </v-text-field>
        </div>
      </v-card-title>
      <v-card-actions> </v-card-actions>
    </popup-filter>
    <statistic-popup
      @confirm="confirmStatistics"
      @cancel="cancelStatistics"
      :maxWidth="maxWidth"
      :width="width"
      ref="confirmStatistics"
    >
      <v-card-title slot="title" class="pt-0 mb-5 pa-0">
        <h4 class="mb-5">Filter the report</h4>
        <div>
          <v-autocomplete
            label="Center"
            style="width: 300px"
            v-model="center"
            :items="centers"
            item-text="location"
            item-value="location"
            @change="searchClass()"
            outlined
            dense
          ></v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            label="Class status"
            style="width: 300px"
            v-model="status"
            :items="statusList"
            item-text="classStatus"
            item-value="classStatus"
            @change="searchClass()"
            outlined
            dense
          ></v-autocomplete>
        </div>
        <div class="d-flex justify-space-between" style="width: 100%">
          <div style="font-size: 14px">Class list</div>
          <div style="font-size: 14px">Selected {{ selected.length }}/{{ filtered.length }}</div>
        </div>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
          class="my-10"
        ></v-progress-linear>
        <v-data-table
          v-if="!isLoading"
          :headers="headers"
          :items="filtered"
          :items-per-page="999999999"
          hide-default-footer
          fixed-header
          :height="maxHeight"
          show-select
          item-key="classID"
          v-model="selected"
          @page-count="pageCount = $event"
          class="elevation-0"
          style="width: 100%"
        >
          <template v-slot:header="{ props }">
            <tr>
              <th v-for="header in props.headers" :key="header.text">
                <div v-if="filters.hasOwnProperty(header.value)">
                  <v-autocomplete
                    chips
                    small-chips
                    deletable-chips
                    hide-details
                    dense
                    multiple
                    :items="columnValueList(header.value)"
                    v-model="filters[header.value]"
                    class="ma-3 my-1"
                  >
                  </v-autocomplete>
                </div>
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-card-title>
      <v-card-actions> </v-card-actions>
    </statistic-popup>
  </v-navigation-drawer>
</template>

<script>
import PopupFilter from '@/components/CenterManagement/FeedBackManagement/PopupFilter.vue'
// import DatePicker from "@/components/Utils/DatePicker.vue";
import dayjs from 'dayjs'
import StatisticPopup from '@/components/StatisticManagement/StatisticPopup.vue'
import { class_managerment } from '@/api/class-management.js'
import { account_managerment } from '@/api/account-management.js'
import iconCalStu from '../../assets/images/icon-cal.svg'
import iconRoadMapStu from '../../assets/images/icon-roadmap.svg'
import iconFeedBackStu from '../../assets/images/icon-feedback.svg'
import iconRecordStu from '../../assets/images/icon-record-student.svg'
import iconRecordMonthly from '../../assets/images/icon-monthy-bar.svg'
// import iconLibrary from '../../assets/images/icon-library.svg'
const querystring = require('querystring')
export default {
  components: {
    'popup-filter': PopupFilter,
    // "date-picker": DatePicker,
    'statistic-popup': StatisticPopup,
  },
  data() {
    return {
      iconCalStu: iconCalStu,
      iconFeedBackStu: iconFeedBackStu,
      iconRecordStu: iconRecordStu,
      iconRoadMapStu: iconRoadMapStu,
      hello: 'salary',
      fromDate: dayjs()
        .add(-parseInt(dayjs().add(-1, 'day').format('DD')), 'day')
        .format('YYYY-MM-DD'),
      toDate: dayjs(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format(
        'YYYY-MM-DD'
      ),
      changeStatus: true,
      width: 400,
      maxWidth: 1000,
      maxHeight: 200,
      headers: [
        {
          text: 'Class code',
          value: 'classCode',
          sortable: false,
        },
      ],
      filters: {
        classCode: [],
      },
      classList: [],
      selected: [],
      isLoading: false,
      centers: [],
      center: localStorage.getItem('statisticCenter') || '',
      status: localStorage.getItem('statisticStatus') || '',
      statusList: [],
      classIds: [],
      items: [
        {
          icon: 'isax fs-24 isax-task',
          typeIcon: '',
          items: [
            {
              title: 'Task Management',
              path: '/task-management/overview',
              functionID: process.env.VUE_APP_ENV_FUNCTION_TASK_MANAGEMENT,
            },
            {
              title: 'Admin Task Report',
              path: '/task-management/admin-task',
              functionID: process.env.VUE_APP_ENV_FUNCTION_ADMIN_TASK_REPPORT,
            },
          ],
          title: 'Task',
          accountType: 'Staff',
        },
        {
          icon: 'isax-building-3',
          typeIcon: '',
          items: [
            {
              title: 'Center Management',
              path: '/center-management/overview',
              functionID: process.env.VUE_APP_ENV_FUNCTION_CENTERS,
            },
            {
              title: 'News Management',
              path: '/center-management/view-news',
              functionID: process.env.VUE_APP_ENV_FUNCTION_NEWS,
            },
          ],
          title: 'Center',
          accountType: 'Staff',
        },
        {
          typeIcon: '',
          icon: 'isax-money',
          items: [
            {
              title: 'Teacher Salary',
              path: '/financial/teacher-salary',
              functionID: process.env.VUE_APP_ENV_FUNCTION_TEACHER_SALARY,
            },
            {
              title: 'QC Salary',
              path: '/financial/qc-salary',
              functionID: process.env.VUE_APP_ENV_FUNCTION_QC_SALARY_MANAGEMENT,
            },
            {
              title: 'My Salary',
              path: '/financial/detail-qc-salary/1',
              functionID: process.env.VUE_APP_ENV_FUNCTION_MY_SALARY,
            },
            {
              title: 'QC Customer Care',
              path: '/financial/qc-customer-care',
              functionID: process.env.VUE_APP_ENV_FUNCTION_QC_CUSTOMER_CARE,
            },
          ],
          title: 'Financial',
          accountType: 'Staff',
        },
        {
          typeIcon: '',
          icon: require('../../assets/images/icon-activity.svg'),
          items: [
            {
              title: 'Activity list',
              path: '/activities/list-activities',
              functionID: process.env.VUE_APP_ENV_FUNCTION_ACTIVITY_LIST,
            },
            {
              title: 'Registration management',
              path: '/activities/register-list',
              functionID: process.env.VUE_APP_ENV_FUNCTION_ACTIVITY_REGISTER,
            },
            {
              title: 'Activity report',
              path: '/activities/report-activities',
              functionID: process.env.VUE_APP_ENV_FUNCTION_ACTIVITY_REPORT,
            },
            {
              title: 'Activity Note',
              path: '/activities/note-activities',
              functionID: process.env.VUE_APP_ENV_FUNCTION_ACTIVITY_NOTE,
            },
          ],
          title: 'Side activity',
          accountType: 'Staff',
        },
        {
          typeIcon: '',
          icon: require('../../assets/images/icon-library.svg'),
          items: [
            {
              title: 'Teacher library',
              path: '/libraries/teacher-library',
              functionID: process.env.VUE_APP_ENV_FUNCTION_TEACHER_LIBRARY,
            },
            {
              title: 'Student library',
              path: '/libraries/student-library',
              functionID: process.env.VUE_APP_ENV_FUNCTION_STUDENT_LIBRARY,
            },
            {
              title: 'Internal library',
              path: '/libraries/internal-library',
              functionID: process.env.VUE_APP_ENV_FUNCTION_INTERNAL_LIBRARY,
            },
          ],
          title: 'Reference library',
          accountType: 'Staff',
        },

        {
          typeIcon: '',
          icon: require('../../assets/images/icon-book-inventory.svg'),
          items: [
            {
              title: 'Inventory Order Detail',
              path: '/book-inventory/inventory-order',
              functionID: process.env.VUE_APP_ENV_FUNCTION_INVENTORY_DETAIL,
            },
            {
              title: 'Inventory Item Statement',
              path: '/book-inventory/inventory-item',
              functionID: process.env.VUE_APP_ENV_FUNCTION_IVENTORY_ITEM,
            },
            {
              title: 'Cash Detail By Product',
              path: '/book-inventory/cash-detail',
              functionID: process.env.VUE_APP_ENV_FUNCTION_IVENTORY_ITEM,
            },
            {
              title: 'Transfer Search',
              path: '/book-inventory/transfer-data',
              functionID: process.env.VUE_APP_ENV_FUNCTION_IVENTORY_ITEM,
            },
            {
              title: 'Course Material Check List',
              path: '/book-inventory/course-material',
              functionID: process.env.VUE_APP_ENV_FUNCTION_IVENTORY_ITEM,
            },
          ],
          title: 'Book Inventory',
          accountType: 'Staff',
        },
        {
          typeIcon: '',
          icon: 'isax-book',
          items: [
            {
              title: 'Calendar',
              path: '/class-management/calendar',
              functionID: process.env.VUE_APP_ENV_FUNCTION_QC_CALENDAR,
            },

            {
              title: 'Daily Classes',
              path: '/class-management/daily-classes',
              functionID: process.env.VUE_APP_ENV_FUNCTION_CLASSES,
            },
            {
              title: 'Daily Absent Teacher',
              path: '/class-management/daily-absent-teacher',
              functionID: process.env.VUE_APP_ENV_FUNCTION_DAILY_ABSENT_TEACHER,
            },
            {
              title: 'Daily Classes by QC',
              path: '/class-management/qc-daily-class',
              functionID: process.env.VUE_APP_ENV_FUNCTION_QC_DAILY_CLASS,
            },
            // {
            //   title: 'Daily Absent Teacher',
            //   path: '/class-management/daily-absent-teacher',
            //   functionID: process.env.VUE_APP_ENV_FUNCTION_DAILY_ABSENT_TEACHER,
            // },
            {
              title: 'Course Management',
              path: '/course-management/overview',
              functionID: process.env.VUE_APP_ENV_FUNCTION_LEVELS,
            },
            {
              title: 'Class Management',
              path: '/class-management/overview',
              functionID: process.env.VUE_APP_ENV_FUNCTION_CLASSES,
            },
            {
              title: 'Monthly Student Comments',
              path: '/class-management/month-student-comments',
              functionID: process.env.VUE_APP_ENV_FUNCTION_REPORT,
            },
            {
              title: 'Student Recording',
              path: '/class-management/student-recording',
              functionID: process.env.VUE_APP_ENV_FUNCTION_STUDENT_RECORDING,
            },
            {
              title: 'Achievements & Rewards',
              path: '/reward',
              functionID: process.env.VUE_APP_ENV_FUNCTION_REPORT,
            },
            {
              title: 'Make-up lessons',
              path: '/makeup-lesson',
              functionID: process.env.VUE_APP_ENV_FUNCTION_MAKEUP_LESSON_REPORT,
            },
            {
              title: 'Room usage situation',
              path: '/room/calendar',
              functionID: process.env.VUE_APP_ENV_FUNCTION_ROOM,
            },
            {
              title: 'Lesson Note',
              path: '/class-management/lesson-note',
              functionID: process.env.VUE_APP_ENV_FUNCTION_LESSON_NOTE,
            },
            {
              title: 'All test in class',
              path: '/all-test/overview',
              functionID: process.env.VUE_APP_ENV_FUNCTION_ALL_TEST,
            },
          ],
          title: 'Academic',
          accountType: 'Staff',
        },
        {
          icon: 'isax-profile-2user',
          active: false,
          typeIcon: '',
          items: [
            {
              title: 'Account list',
              path: '/account-management/overview',
              functionID: process.env.VUE_APP_ENV_FUNCTION_USER_PROFILE,
            },
            {
              title: "Teacher's time available",
              path: '/center-management/time-available',
              functionID: process.env.VUE_APP_ENV_FUNCTION_TEACHER_FREE_TIME,
            },
          ],
          title: 'Account Information',
          accountType: 'Staff',
        },
        {
          icon: 'isax-setting',
          typeIcon: '',
          items: [
            {
              title: 'My leave of absence request',
              path: '/application/leave-of-absence-request',
            },
          ],
          title: 'Application',
          accountType: 'Staff',
        },
        {
          icon: 'isax-edit',
          typeIcon: '',
          items: [
            {
              title: 'Teacher leave of absence',
              path: '/request/teacher-leave-of-absence-request',
              functionID: process.env.VUE_APP_ENV_FUNCTION_TEACHER_ABSENCE,
            },
            {
              title: 'Staff leave of absence',
              path: '/request/staff-leave-of-absence-request',
              functionID: process.env.VUE_APP_ENV_FUNCTION_TEACHER_ABSENCE,
            },
          ],
          title: 'Request',
          accountType: 'Staff',
        },
        {
          icon: 'isax-stickynote',
          typeIcon: '',
          items: [
            {
              title: 'Teachers on leave',
              path: '/statistics/teacher-on-leave',
              functionID: process.env.VUE_APP_ENV_FUNCTION_REPORT,
            },
            {
              title: 'Staffs on leave',
              path: '/statistics/staff-on-leave',
              functionID: process.env.VUE_APP_ENV_FUNCTION_REPORT,
            },

            {
              title: 'Teacher changes by class',
              functionID: process.env.VUE_APP_ENV_FUNCTION_REPORT,
            },
          ],
          title: 'Statistics',
          accountType: 'Staff',
        },
        {
          icon: 'isax-calendar',
          typeIcon: '',
          items: [
            {
              iconImg: iconCalStu,
              title: 'Calendar',
              path: '/my-calendar/overview',
            },
            {
              iconImg: require('../../assets/images/task-management-icon.svg'),
              title: 'Task Management',
              path: '/task-management/overview',
            },
            {
              iconImg: require('../../assets/images/icon-feetime.svg'),
              title: 'Register Free Time',
              path: '/my-calendar/register-free-time',
            },
            {
              iconImg: require('../../assets/images/icon-leave.svg'),
              title: 'Leave of Absent',
              path: '/my-calendar/leave-of-absence-history',
            },
            {
              iconImg: require('../../assets/images/icon-dailyclass.svg'),
              title: 'Daily Classes',
              path: '/class-management/daily-classes',
            },
            {
              iconImg: require('../../assets/images/icon-library.svg'),
              title: 'Teacher library',
              path: '/libraries/teacher-library',
            },
            {
              iconImg: require('../../assets/images/icon-library.svg'),
              title: 'Student library',
              path: '/libraries/student-library',
            },
          ],
          title: 'Schedule',
          accountType: 'Teacher',
        },
        {
          icon: 'isax-briefcase',
          typeIcon: '',
          items: [
            {
              iconImg: require('../../assets/images/icon-salary.svg'),
              title: 'Salary',
              path: '/salary/list',
            },
          ],
          title: 'Salary record',
          accountType: 'Teacher',
        },
        {
          icon: 'isax-calendar',
          typeIcon: '',
          items: [
            {
              title: 'Lịch học',
              iconImg: iconCalStu,
              path: '/student-calendar/overview',
            },
            {
              title: 'Lộ trình khóa học',
              iconImg: iconRoadMapStu,
              path: '/student-roadmap/overview',
            },
            {
              title: 'Phản hồi',
              iconImg: iconFeedBackStu,
              path: '/feedback-management-messenger',
            },
            {
              title: 'Bài tập ghi âm',
              iconImg: iconRecordStu,
              path: '/record-by-stu',
            },
            {
              title: 'Nhận xét hàng tháng',
              iconImg: iconRecordMonthly,
              path: '/student-monthly-comment',
            },
            {
              title: 'Thành tích và khen thưởng',
              iconImg: require('../../assets/images/icon-reward.svg'),
              path: '/reward-student',
            },
            {
              title: 'Thư viện tham khảo',
              iconImg: require('../../assets/images/icon-library.svg'),
              path: '/libraries/student-library',
            },
            {
              title: 'Bài kiểm tra',
              iconImg: require('../../assets/images/all-test-icon.svg'),
              path: '/all-test/student-test',
            },
          ],
          title: 'Schedule',
          accountType: 'Student',
        },
        {
          icon: require('../../assets/images/icon-operation.svg'),
          typeIcon: 'imgIcon',
          items: [
            {
              title: 'Student Attendance Report',
              path: '/report/student-attendance',
              functionID: process.env.VUE_APP_ENV_FUNCTION_STUDENT_ATTENDANCE_REPORT,
            },
            {
              title: 'Absent Students Report',
              path: '/statistics/student-absent-report',
              functionID: process.env.VUE_APP_ENV_FUNCTION_STUDENT_ATTENDANCE_REPORT,
            },
            {
              title: 'Student Homework Report',
              path: '/report/student-homework',
              functionID: process.env.VUE_APP_ENV_FUNCTION_STUDENT_ATTENDANCE_REPORT,
            },
            {
              title: 'No Homework Report',
              path: '/no-home-work',
              functionID: process.env.VUE_APP_ENV_FUNCTION_NO_HOMEWORK_REPORT,
            },
            {
              title: 'Customer Care Report',
              path: '/report/custoner-care',
              functionID: process.env.VUE_APP_ENV_FUNCTION_CUSTOMER_CARE_REPORT,
            },
          ],
          title: 'Operation Reports',
          accountType: 'Staff',
        },
        {
          icon: require('../../assets/images/icon-salereport.svg'),
          items: [
            {
              title: 'Telesales Report',
              path: '/telesales',
              functionID: process.env.VUE_APP_ENV_FUNCTION_TELESALES_REPORT,
            },
            {
              title: 'Visitor Report',
              path: '/report/visitor',
              functionID: process.env.VUE_APP_ENV_FUNCTION_VISITOR_REPORT,
            },
            {
              title: 'Sale Report',
              path: '/report/sale-report',
              functionID: process.env.VUE_APP_ENV_FUNCTION_SALE_REPORT,
            },

            {
              title: 'Daily Cash',
              path: '/report/daily-cash',
              functionID: process.env.VUE_APP_ENV_FUNCTION_DAILY_CASH,
            },
          ],
          title: 'Sale Reports',
          accountType: 'Staff',
          typeIcon: 'imgIcon',
        },

        {
          icon: require('../../assets/images/icon-cm.svg'),
          typeIcon: 'imgIcon',
          items: [
            {
              title: 'Student Average Report',
              path: '/report/student-av-report',
              functionID: process.env.VUE_APP_ENV_FUNCTION_STUDENT_AVERAGE,
            },
            {
              title: 'Outstanding Report',
              path: '/report/outstanding-report',
              functionID: process.env.VUE_APP_ENV_FUNCTION_OUTSTANDING,
            },
            {
              title: 'Stopped Student Report',
              path: '/report/StoppedStudent',
              functionID: process.env.VUE_APP_ENV_FUNCTION_STUDENT_STOPPED,
            },
            {
              title: 'KPI Report',
              path: '/report/kpi',
              functionID: process.env.VUE_APP_ENV_FUNCTION_KPI_REPORTS,
            },
            {
              title: 'Course Overview',
              path: '/report/courseOverview',
              functionID: process.env.VUE_APP_ENV_FUNCTION_COURSE_OVERVIEW,
            },
            {
              title: 'Revenue Report',
              path: '/report/RevenueReport',
              functionID: process.env.VUE_APP_ENV_FUNCTION_REVENUE_REPORT,
            },
            {
              title: 'Center Revenue',
              path: '/report/revenue-report',
              functionID: process.env.VUE_APP_ENV_FUNCTION_REVENUE_CENTER_REPORT,
            },
          ],
          title: 'CM Reports',
          accountType: 'Staff',
        },
        {
          typeIcon: 'imgIcon',
          icon: require('../../assets/images/icon-account.svg'),

          items: [
            {
              title: 'Daily Spending Diary',
              path: '/report/daily-spending-diary',
              functionID: process.env.VUE_APP_ENV_FUNCTION_DAILY_SPENDING_DIARY,
            },
            {
              title: 'Book Inventory',
              path: '/report/book-inventory',
              functionID: process.env.VUE_APP_ENV_FUNCTION_BOOK_INVENTORY,
            }
          ],
          title: 'Accounting Reports',
          accountType: 'Staff',
        },
      ],
      currentCenter: JSON.parse(localStorage.getItem('currentCenter')),
      locationPermission: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      functionRole: JSON.parse(localStorage.getItem('functionRole')),
      accountTypeID: localStorage.getItem('accountTypeID'),
      currentUser: JSON.parse(localStorage.getItem('currentUser')),
      listRole: [],
      count: 0,
      accountTy: null,
      active: false,
      resizeImage: false,
    }
  },
  mounted() {
    this.locationPermission.forEach(x => {
      if (this.currentCenter === x.locationID) {
        this.items = this.items.filter(y => y.accountType == x.accountTypeName)
        this.accountTy = x.accountTypeName
      }
    })
    this.items.forEach(x => {
      if (this.accountTy == 'Staff') {
        this.count = x.items.length
        x.items.forEach(y => {
          if (
            this.functionRole.filter(
              z =>
                (z.functionID == y.functionID && z.cFull == true) ||
                (z.functionID == y.functionID && z.cRead == true) ||
                (z.functionID == y.functionID && z.cModify == true)
            ).length == 1
          ) {
            x.items.push(y)
          }
        })
        x.items.splice(0, this.count)
      }
      if (x.items.length > 0) {
        if (this.currentUser.userInfo.accountTypeID == 5) {
          this.listRole.push({
            accountType: x.items.length > 0 ? x.accountType : null,
            icon: x.items.length > 0 ? x.icon : null,
            items: x.items,
            title: x.items.length > 0 ? x.title : null,
          })
        } else if (this.currentUser.userInfo.accountTypeID == 4) {
          this.listRole.push({
            accountType: x.items.length > 0 ? x.accountType : null,
            icon: x.items.length > 0 ? x.icon : null,
            items: x.items,
            title: x.items.length > 0 ? x.title : null,
          })
          this.listRole = this.listRole.filter(x => x.accountType == 'Teacher')
        } else if (this.currentUser.userInfo.accountTypeID == 1) {
          this.listRole.push({
            accountType: x.items.length > 0 ? x.accountType : null,
            icon: x.items.length > 0 ? x.icon : null,
            items: x.items,
            title: x.items.length > 0 ? x.title : null,
          })
          this.listRole = this.listRole.filter(x => x.accountType == 'Student')
        }
      }
      if (
        x.title == 'Application' &&
        this.currentUser.userInfo.accountTypeID != 1 &&
        this.currentUser.userInfo.accountTypeID != 2 &&
        this.currentUser.userInfo.accountTypeID != 3
      ) {
        this.listRole.push({
          icon: 'isax-setting',
          items: [
            {
              title: 'My leave of absence request',
              path: '/application/leave-of-absence-request',
            },
          ],
          title: 'Application',
          accountType: 'Staff',
        })
      }
    })
    if (this.currentUser.userInfo.accountTypeID === 5) {
      this.listRole.unshift({
        accountType: 'Staff',
        icon: require('../../assets/images/announ_menu.svg'),
        path: '/announcement',
        items: [],
        title: 'Announcement',
      })
      this.listRole.unshift({
        accountType: 'Staff',
        icon: require('../../assets/images/icon-fb-management.svg'),
        path: '/feedback-management-admin',
        items: [],
        title: 'Feedback Management',
      })
      this.listRole.unshift({
        accountType: 'Staff',
        icon: require('../../assets/images/icon-fb-admin.svg'),
        path: '/feedback-management-messenger',
        items: [],
        title: 'Feedback New',
      })

      this.listRole.unshift({
        accountType: 'Staff',
        icon: 'isax fs-24 isax-grid-2',
        path: '/dashboard',
        items: [],
        title: 'Dashboard',
      })
    }
  },
  computed: {
    filtered() {
      return this.classList.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
    //Item in sidebar that have child
    groupLink() {
      return this.items.filter(item => item.accountType === 'Staff')
    },
  },
  methods: {
    formatTitle(title) {
      switch (title) {
        case 'Center Management':
          title = 'Center information'
          break
        case 'News Management':
          title = 'News management'
          break
        case 'Feedback':
          title = 'Feedback management<br/>Download file excel'
          break
        case 'Notification Management':
          title = 'Create notifications<br/>Set up automatic notifications'
          break
        case 'Teacher Salary':
          title = 'Teacher salary<br/>Download file excel'
          break
        case 'Daily Classes':
          title = 'Class details<br/>Lesson report<br/>Attendance'
          break
        case 'Course Management':
          title = 'Course information'
          break
        case 'Class Management':
          title =
            'Class schedule - Lesson report & Attendance<br/>Class test schedule<br/>Registered students list'
          break
        case 'Account list':
          title =
            "Active/inactive account<br/>Teacher's calendar<br/>Teacher's time available<br/>Teacher's leave of absence application"
          break
        case 'Teacher calendar':
          title = 'Class detail<br/>Lesson detail<br/>Student attendance<br/>Lesson report'
          break
        case "Teacher's time available":
          title = 'Find teachers with available time'
          break
        case 'Students attendance report':
          title = 'Students by class<br/>Download file excel'
          break
        case 'Teacher changes by class':
          title = 'Teacher changes by class<br/>Download file excel'
          break
        case 'Calendar':
          title = 'Class detail<br/>Lesson detail<br/>Student Attendance<br/>Lesson report'
          break
        case 'Register Free Time':
          title = 'Register free time'
          break
        case 'Salary':
          title = 'Salary detail<br/>Download file excel'
          break
      }
      return title
    },
    async getListCenters() {
      await account_managerment.getListCenter().then(res => {
        if (res) {
          this.centers = res
        }
      })
    },
    async getClassStatus() {
      await class_managerment.getClassStatus().then(res => {
        if (res) {
          res.unshift({
            classStatusID: 6,
            classStatus: 'All',
          })
          this.statusList = res
        }
      })
    },
    removeClass() {
      const element = document.querySelector('.v-list-group--active')
      if (element) {
        const childElement = element.querySelector('.v-list-item--active')
        const groupItem = element.querySelector('.v-list-group__items')

        if (childElement && groupItem) {
          groupItem.style.display = 'none'
          childElement.setAttribute('aria-expanded', 'false')
          childElement.classList.remove('v-list-item--active')
          element.classList.remove('v-list-group--active')
          element.classList.remove('primary--text')
          this.changeStatus = true
        }
      }
    },
    formatCenter(location) {
      switch (location) {
        case 'CEC Văn quán 1':
          location = 1
          break
        case 'CEC Văn quán 2':
          location = 2
          break
        case 'CEC Trần Phú':
          location = 3
          break
        case 'CEC Mỗ Lao':
          location = 4
          break
        case 'CEC Hapulico':
          location = 5
          break
        case 'CEC Liễu Giai':
          location = 6
          break
        case 'CEC Cầu Giấy':
          location = 7
          break
        case 'CEC Times City':
          location = 8
          break
        case 'NinjaKid':
          location = 9
          break
        case 'SYSTEM':
          location = 10
          break
        case 'CEC Phạm Văn Đồng':
          location = 11
          break
        case 'CEC Ngoại Giao Đoàn':
          location = 12
          break
        case 'Teacher System':
          location = 13
          break
      }
      return location
    },
    async searchClass() {
      this.isLoading = true
      const body = {
        pLocationid: this.formatCenter(this.center),
        pStatus: this.status != 'All' ? this.status : '',
      }
      await class_managerment.getClassByLocation(querystring.stringify(body)).then(res => {
        if (res) {
          this.classList = res
        }
      })
      this.isLoading = false
    },
    //Check nav with no child
    isRootItem(index, currPath = null) {
      return currPath
        ? currPath
        : (index === 0 && this.$route.path.includes('/dashboard')) ||
            (index === 1 && this.$route.path.includes('/feedback-management-messenger')) ||
            (index === 2 && this.$route.path.includes('/feedback-management-admin')) ||
            (index === 3 && this.$route.path.includes('/announcement'))
    },
    //Check nav that contain child
    isGroupItem(index) {
      const targetArr = this.listRole.slice(4)
      // Check if the index is valid
      if (index >= 0 && index < targetArr.length) {
        const element = targetArr[index]
        // Check if any path matches the given path
        return element.items.some(item => item.path === this.$route.path)
      }
      return false
    },
    //Check child to active
    isActiveItemChild(path, title) {
      return path == this.$route.path ||
        (!path &&
          title == 'Feedback' &&
          this.$route.path.includes('/center-management/feedback-management')) ||
        (!path &&
          title == 'Students by class' &&
          this.$route.path.includes('/statistics/student-by-class')) ||
        (!path &&
          title == 'Students attendance report' &&
          this.$route.path.includes('/statistics/student-attendance')) ||
        (!path &&
          title == 'Teacher changes by class' &&
          this.$route.path.includes('/statistics/teacher-changes-by-class'))
        ? 'menu-child-active-border'
        : 'menu-title'
    },
    filterPopUp(title) {
      this.active = true
      if (
        title === 'Feedback' &&
        this.$router.history.current.name !== 'center-management-feedback'
      ) {
        this.$refs.confirmFilter.open()
      } else if (
        (title === 'Students by class' &&
          this.$router.history.current.name !== 'statistics-student-by-class') ||
        (title === 'Students attendance report' &&
          this.$router.history.current.name !== 'statistics-student-attendance') ||
        (title === 'Teacher changes by class' &&
          this.$router.history.current.name !== 'statistics-teacher-changes-by-class')
      ) {
        this.$refs.confirmStatistics.open(title)
        this.getListCenters()
        this.getClassStatus()
      }
    },
    cancelFilter() {},
    confirmFilter() {
      this.$router.push({
        name: 'center-management-feedback',
        params: {
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
      })
    },
    confirmStatistics(title) {
      this.classIds = []
      for (var i = 0; i < this.selected.length; i++) {
        if (this.classIds.indexOf(this.selected[i].classID) === -1) {
          this.classIds.push(this.selected[i].classID)
        }
      }
      localStorage.setItem('statisticCenter', this.center)
      localStorage.setItem('statisticStatus', this.status)
      localStorage.setItem('statisticClassIdList', JSON.stringify(this.classIds))
      if (title === 'Students by class') {
        this.$router.push({
          name: 'statistics-student-by-class',
          query: {
            classIds: this.classIds,
          },
        })
      } else if (title === 'Students attendance report') {
        this.$router.push({
          name: 'statistics-student-attendance',
          query: {
            classIds: this.classIds,
          },
        })
      } else if (title === 'Teacher changes by class') {
        this.$router.push({
          name: 'statistics-teacher-changes-by-class',
          query: {
            classIds: this.classIds,
          },
        })
      }
    },
    columnValueList(val) {
      return this.classList.map(d => d[val]).filter(y => y)
    },
    updateFromDate(event) {
      this.fromDate = event
    },
    updateToDate(event) {
      this.toDate = event
    },
    checkTime(i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss">
.menu-child-active-border {
  font-weight: 600;
  color: #fff;
}
.other-menu-title-active-border {
  border-left: 4px solid #f7ac1a;
}

.menu-title-active-border,
.menu-group-title-active {
  &::before {
    display: block;
    content: '';
    background: #f7ac1a;
    height: 40px;
    width: 4px;
    position: absolute;
    margin-left: -51px;
  }
  &::after {
    background-color: #fff;
    content: '';
    bottom: 0;
    left: -100px;
    right: -100px;
    top: 0;
    opacity: 0.12;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    pointer-events: none;
    position: absolute;
  }
}
.menu-title {
  color: #ffffff !important;
  opacity: 0.75;
  cursor: pointer;
}

.dashboard {
  opacity: 1;
}

nav {
  &.v-navigation-drawer {
    .v-navigation-drawer__content {
      .v-icon,
      .v-list-item__icon,
      .v-list-item__title {
        color: #fff;
      }
    }

    .v-navigation-drawer__border {
      width: 2px;
      background-color: #ececf6 !important;
    }
  }
}

.v-application .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 55px !important;
}
.v-application
  .v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 24px !important;
}
.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0;
}
</style>
